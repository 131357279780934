// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


/******************************/
/******** MENU ********/
/******************************/

nav.module-menu .menu-desktop {
    background: url("../images/accueil_nav.png") no-repeat;
    background-size: cover;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu {
    height: 15%;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu-top {
    height: 33%;
    margin-top: -35px;
    padding-bottom: 30px;
}
nav.module-menu .menu-desktop .main-menu-top ul li.phone {
    margin-left: 6.1rem !important;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a {
    color: #000;
    font-weight: 600;
    text-transform: initial;
    font-size: $font-size-24;
    padding: 0;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.lang > a {
    color: #000;
    font-weight: 600;
    text-transform: initial;
    font-size: $font-size-24;
    line-height: 1;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.item.active > a {
     border-bottom: 1px solid #000;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li:hover > a, nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.lang:hover a {
    color: #000;
    border-bottom: 1px solid #000;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.phone > a {
    color: #000;
    font-weight: 600;
    text-transform: initial;
    font-size: $font-size-24;
}
nav.module-menu .menu-desktop .main-menu-top li.phone a .phonenumber {
    font-size: $font-size-28;
}
nav.module-menu .menu-desktop .main-menu-top li.phone:hover a .phonenumber {
    color: #000;
}
nav.module-menu .menu-desktop .wrapper ul li.lang, nav.module-menu .menu-desktop .wrapper ul li.item {
    border-right: 2px solid #000;
    padding: 0 20px 0 20px;
    margin: 0 !important;
}
nav.module-menu .menu-desktop .wrapper ul li.lang:nth-child(2), nav.module-menu .menu-desktop .wrapper ul li.item:nth-child(6) {
    border-right: none;
}

/***** MENU MOBILE *****/

nav.module-menu #menu-mobile ul li > a, nav.module-menu #menu-mobile ul li.lang > a div {
    font-size: 1rem;
    font-weight: 600;
    text-transform: initial;
    color: #000 !important;
}
nav.module-menu #menu-mobile ul li.phone > a, nav.module-menu #menu-mobile ul li.lang > a {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: initial;
    color: #000 !important;
}


#logoQc {
    position: relative;
    bottom: 10%;
    padding-left: 2%;
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocBurst {
    padding: 50px 0 100px 0;
    display: flex;
    justify-content: space-evenly;
    p {
        font-weight: 700;
        text-align: center;
        font-size: $font-size-18;
        padding-top: 15px;
        color: #242422;
        line-height: 1.5;
    }
    img.img-responsive.hover {
        display: none;
    }
    .bloc:hover {
        img.img-responsive.defaut {
            display: none;
        }
        img.img-responsive.hover {
            display: block;
        }
    }
}

#blocIntro {
    text-align: center;
    padding: 50px 0;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #242422;
    }
}

#texte_section-noir {
    display: flex;
    .left-side {
        background: url("../images/accueil_section02_bg.png") no-repeat;
        background-size: cover;
        width: 50%;
        .texte {
            position: relative;
            top: 22%;
            left: 5%;
        }
        p {
            font-weight: 600;
            font-size: $font-size-18;
            color: #fff;
            width: 83%;
            text-align: right;
        }
    } 
    .right-side {
        width: 50%;
    }
}

#blocBackground {
    text-align: center;
    background: url("../images/accueil_section03_bg.png") no-repeat;
    background-size: cover;
    padding: 155px 2%;
    margin-top: -131px;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
    p.first {
        padding-bottom: 35px;
    }
}

#blocLogo {
    padding: 100px 5%;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #242422;
    }
    .blocLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
    }
}

#blocFooter {
    display: flex;
    justify-content: space-evenly;
    background: url(../images/accueil_footer_bg.jpg) no-repeat;
    background-size: cover;
    padding: 150px 0 0 0;
    align-items: center;
    h3 {
        font-weight: 600;
        font-size: $font-size-24;
        color: #656562;
        padding-bottom: 20px;
        text-align: right;
    }
    a {
        font-weight: 400;
        font-size: $font-size-18;
        color: #fff;
        line-height: 1.35;
    }
    a:hover {
        color: #656562;
    }
    .bloc {
        position: relative;
        bottom: 30px;
        .telephone, .adresse {
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
        }
        .menu {
            display: flex;
            text-align: right;
            .left-side {
                padding-right: 30px;
            }
        }
    }
}

#blocFooter p {
    font-weight: 400;
    font-size: $font-size-18;
    color: #fff;
    padding-left: 15px;
    margin: 0;
}
#blocFooter p.codePostal {
    text-align: right;
    line-height: 1;
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoordonnees {
    display: flex;
    padding: 50px 0 100px 5%;
    .left-side, .right-side {
        width: 50%;
    }
    .bloc {
        display: flex;
        align-items: center;
        p {
            font-weight: 600;
            font-size: $font-size-30;
            margin: 0;
        }
        .icon {
            padding-right: 3%;
        }
        .texte.courriel {
            margin-left: -10px;
        }
    }
    .bloc.last {
        padding: 80px 0;
    }
    .section-texte {
        p {
            font-weight: normal;
            font-size: $font-size-30;
            margin: 0;
            line-height: 1.5;
        }
        .bloc-marcel {
            padding-bottom: 30px;
        }
    }
}

/*******************************************************************************
* GLISSIÈRES DE SÉCURITÉ
*******************************************************************************/

#blocIntro_glissiere {
    text-align: center;
    padding: 50px 0;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #242422;
    }
}

#blocBackground_glissiere {
    text-align: center;
    background: url("../images/glissiere_section02_bg.jpg") no-repeat;
    min-height: 869px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -3%;
    padding-top: 7%;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
    p.first {
        padding-bottom: 35px;
    }
}

#bloc50-50 {
    display: flex;
    padding: 0 5% 75px 5%;
    align-items: center;
    .left-side {
        width: 48%;
    }
    .right-side {
        width: 45%;
        h3 {
            font-weight: 700;
            font-size: $font-size-24;
            color: #000;
            padding-bottom: 35px;
        }
        p {
            font-weight: 600;
            font-size: $font-size-22;
            margin: 0;
            text-align: justify;
        }
    }
}

#blocChartes {
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-bottom: 75px;
    .left-side {
        margin-right: 7%;
        .bouton {
            padding: 50px 10% 0 10%;
            a {
                background: #64b864;
                color: #fff;
                padding: 15px 25px; 
                font-weight: 600;
                font-size: $font-size-22;
                transition: 0.3s;
                &:hover {
                    color: #000;
                    transition: 0.3s;
                }
            }
        }
    }
}

#blocImages {
    display: flex;
    flex-wrap: wrap;
    .image {
        width: 20%;
    }
}

#blocVideo {
    padding: 100px 2%;
    text-align: center;
    position: relative;
    h3 {
        font-weight: 700;
        font-size: $font-size-24;
        color: #000;
        padding-bottom: 25px;
    }
    &::before {
        content: "";
        position: absolute;
        width: 852px;
        height: 339px;
        left: 0;
        bottom: 0;
        background: url("../images/glissiere_section05_img01.png") no-repeat;
        z-index: -1;
    }   
}

/*******************************************************************************
* GLISSIÈRES DE SÉCURITÉ ESTHÉTIQUES
*******************************************************************************/

#blocIntro_esthetique {
    text-align: center;
    padding: 50px 0;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #242422;
    }
}

#blocBackground_esthetique {
    text-align: center;
    background: url("../images/glissiere-esthetique_section02_bg.jpg") no-repeat;
    background-size: cover;
    min-height: 491px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
    p.first {
        padding-bottom: 35px;
    }
}

#blocTexte {
    background: url("../images/glissiere-esthetique_section04_bg.jpg") no-repeat;
    background-size: cover;
    padding: 180px 2%;
    margin-top: -35px;
    .texte {
        width: 86%;
        position: relative;
        top: 75px;
    }
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
        text-align: justify;
    }
}

#blocImages_esthetique {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    .image {
        width: 20%;
    }
}

/*******************************************************************************
* PRODUITS GIFAB
*******************************************************************************/

#blocBurst_produit {
    padding: 100px 0;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    p {
        font-weight: 700;
        text-align: center;
        font-size: $font-size-18;
        padding-top: 15px;
        color: #242422;
    }
    img.img-responsive.hover {
        display: none;
    }
    .bloc:hover {
        img.img-responsive.defaut {
            display: none;
        }
        img.img-responsive.hover {
            display: block;
        }
    }
}

#blocBackground_produits {
    text-align: center;
    background: url("../images/produits_section02_bg_v2.png") no-repeat;
    min-height: 869px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -10%;
    padding-top: 10%;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
    p.first {
        padding-bottom: 35px;
    }
}

#blocTexte-noir {
    text-align: center;
    background: url("../images/produits_section03_bg.png") no-repeat;
    background-size: cover;
    padding: 170px 2%;
    margin-top: -225px;
    text-align: center;
    .texte {
        position: relative;
        top: 66px;
    }
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
}

#blocPDF {
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;
    a.bloc {
        width: 14%;
        padding: 5px 5px 40px 5px;
        .pdf {
            h3 {
                font-weight: 700;
                font-size: $font-size-18;
                color: #000;
                text-align: center;
                padding-bottom: 20px;
                height: 75px;
                transition: 0.3s;
            }
        }
        :hover {
            h3 {
                color: #64b864;
                transition: 0.3s;
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#blocIntro_service {
    text-align: center;
    padding: 50px 0;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #242422;
    }
}

#blocBackground_service {
    text-align: center;
    background: url("../images/services_section02_bg.jpg") no-repeat;
    background-size: cover;
    min-height: 869px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -120px;
    p {
        font-weight: 600;
        font-size: $font-size-18;
        color: #fff;
    }
    p.first {
        padding-bottom: 35px;
        padding-top: 6%;
    }
}

#blocImages_services {
    display: flex;
    flex-wrap: wrap;
    padding: 75px 0 100px 0;
    .image {
        width: 20%;
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#blocRealisation {
    padding: 50px 5% 75px 5%;
    .bloc {
        display: flex;
        padding-bottom: 60px;
        .left-side {
            width: 35%;
            padding-right: 70px;
        }
        .right-side {
            width: 60%;
            h3 {
                font-weight: 700;
                font-size: $font-size-24;
                color: #000;
                padding-bottom: 30px;
            }
            p {
                font-weight: 600;
                font-size: $font-size-24;
                color: #000;
            }
        }
    }

}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#blocFormulaire {
    padding: 50px 2%;
    select#besoins {
        border: 1px solid #000;
        box-shadow: 2px 3px rgba(0,0,0, 0.1);
        height: 50px;
        font-size: 16px;
    }
    #right {
        clear: both;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 25px;
        label.image {
            color: #000;
            font-weight: normal;
            font-size: $font-size-24;
            text-transform: initial;
            border-bottom: 1px solid #000;
        }
        .btn {
            border: none;
            background: #64b864;
            padding: 22px;
            font-size: 1rem;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1780px) {

#bloc50-50 .left-side {
    width: 55%;
}
}

@media screen and (max-width: 1755px) {

#blocRealisation .bloc .left-side {
    width: 35%;
}
}

@media screen and (max-width: 1650px) {

#logoQc {
    bottom: 5%;
    padding-left: 2%;
}
#blocBackground_produit p br, #blocTexte-noir p br, #blocBackground_produits p br {
    display: none;
}
#blocBackground_produits {
    padding: 10% 5% 0 5%;
}
#blocBackground_glissiere {
    margin-top: -8%;
}
}

@media screen and (max-width: 1560px) {
 
#blocBackground_glissiere p br {
    display: none;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.lang > a {
    font-size: 1rem;
}
.slideshow-wrap .caption {
    top: 65%;
    left: 40%;
}
#blocBackground {
    padding: 155px 2%;
}
#blocBackground p br {
    display: none;
}
#blocRealisation .bloc .left-side {
    width: 45%;
}
#bloc50-50 {
    padding: 0 2% 150px 2%;
}
#bloc50-50 .left-side {
    width: 70%;
}
#bloc50-50 .right-side {
    width: 55%;
}
#blocTexte {
    padding: 115px 2%;
}
#blocPDF a.bloc {
    width: 20%;
}
#texte_section-noir .left-side .texte {
    top: 16%;
    left: 5%;
}
#blocBackground p.first {
    padding-top: 35px;
}
#bloc50-50 {
    padding: 0 2% 100px 2%;
}
#blocBackground_produits {
    margin-top: -13%;
}
#blocBackground_glissiere {
    padding: 7% 5% 0 5%;
}
#blocLogo .logo {
    padding: 0 35px;
}
}

@media screen and (max-width: 1475px) {
 
nav.module-menu .menu-desktop .wrapper ul li.lang, nav.module-menu .menu-desktop .wrapper ul li.item {
    padding: 0 15px 0 15px;
}
.slideshow-wrap .caption {
    top: 62%;
    left: 43%;
}
}

@media screen and (max-width: 1415px) {
 
nav.module-menu .menu-desktop .wrapper ul li.lang, nav.module-menu .menu-desktop .wrapper ul li.item {
    padding: 0 12px 0 12px;
}
#blocIntro {
    padding: 100px 5% 50px 5%;
}
#bloc50-50 .left-side {
    width: 50%;
    padding-right: 80px;
}
#bloc50-50 .right-side {
    width: 50%;
}
}

@media screen and (max-width: 1380px) {
 
nav.module-menu .menu-desktop .wrapper ul li.lang, nav.module-menu .menu-desktop .wrapper ul li.item {
    padding: 0 8px 0 8px;
}
.slideshow-wrap .caption {
    left: 45%;
}
#blocCoordonnees .bloc p, #blocCoordonnees .section-texte p {
    font-size: 1.3rem;
}
#texte_section-noir .left-side .texte {
    top: 10%;
}
}

@media screen and (max-width: 1335px) {
 
.slideshow-wrap .caption {
    left: 45%;
    top: 61%;
}
}

@media screen and (max-width: $size-md-max) {
 
nav.module-menu .menu-desktop {
    height: 145px;
}
nav.module-menu .menu-desktop .main-menu-top li.phone a .phonenumber {
    font-size: 1.25rem;
}
.slideshow-wrap .caption {
    left: 50%;
    top: 58%;
}
#blocRealisation .bloc .left-side {
    width: 55%;
}
#blocTexte .texte {
    top: 55px;
}
#blocImages_services {
    padding: 0 0 100px 0;
}
#blocBackground_service {
    margin-top: -155px;
}
#blocIntro_service {
    padding: 0px 0 50px 0;
}
#blocBurst_produit ::before {
    right: -135px;
}
#blocIntro {
    padding: 50px 5%;
}
#blocBackground_produits {
    margin-top: -18%;
}
#blocLogo .logo {
    padding: 0 30px;
}
}

@media screen and (max-width: 1170px) {

#blocRealisation .bloc .left-side {
    width: 60%;
}
#blocBackground_glissiere p br {
    display: none;
}
#blocIntro_glissiere br, #blocIntro_esthetique p br, #blocIntro_service p br {
    display: none;
}
#blocIntro_glissiere {
    padding: 0 2%;
}
#blocTexte {
    background: #242422;
    padding: 75px 2%;
    margin-top: 0;
}
#blocTexte .texte {
    width: 100%;
    position: initial;
}
#blocIntro_esthetique {
    padding: 50px 2% 50px 2%;
}
#blocBurst_produit ::before {
    display: none;
}
#blocBackground_produit {
    padding: 230px 2%;
}
#texte_section-noir .right-side {
    display: none;
}
#texte_section-noir .left-side {
    background: none;
    width: 100%;
}
#texte_section-noir .left-side .texte {
    position: initial;
}
#texte_section-noir {
    background: #242422;
    padding: 100px 5%;
}
#blocBackground {
    padding: 90px 2%;
    margin-top: -80px;
}
#texte_section-noir .left-side p {
    width: 100%;
}
#blocIntro {
    padding: 0 5% 50px 5%;
}
#blocRealisation .bloc .right-side h3 {
    padding-bottom: 15px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.lang > a {
    font-size: 0.8rem;
}
#blocFooter .bloc {
    position: initial;
    padding-bottom: 35px;
    width: 100%;
    text-align: center;
}
#blocFooter {
    padding: 30% 0 0 0;
    flex-direction: column;
    margin-top: -275px;
}
#blocFooter h3 {
    text-align: center;
}
#blocFooter .bloc .telephone, #blocFooter .bloc .adresse {
    justify-content: center;
}
#blocFooter p.codePostal {
    text-align: center;
}
#blocFooter .bloc .menu {
    position: relative;
    left: 25%;
}
#blocLogo {
    padding: 100px 5%;
}
#blocCoordonnees {
    flex-direction: column;
    padding: 50px 2% 100px 2%;
}
#blocCoordonnees .left-side, #blocCoordonnees .right-side {
    width: 100%;
}
#blocCoordonnees .bloc {
    padding-bottom: 10px;
}
#blocCoordonnees .bloc.last {
    padding: 40px 0;
}
#blocCoordonnees .bloc .icon {
    padding-right: 0;
    width: 6%;
}
#blocCoordonnees .left-side .section-texte {
    padding-bottom: 50px;
    padding-left: 6%;
}
#blocRealisation .bloc {
    flex-direction: column;
}
#blocRealisation .bloc .left-side {
    width: 95%;
    padding: 0;
}
#blocRealisation .bloc .left-side .img-responsive {
    margin: auto;
}
#blocRealisation .bloc .right-side {
    width: 75%;
    margin: auto;
    padding-top: 26px;
}
#blocIntro_glissiere {
    padding: 0 2% 25px 2%;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side {
    width: 100%;
    padding-right: 0;
}
#bloc50-50 .right-side {
    width: 100%;
    padding-top: 35px;
}
#bloc50-50 .left-side .img-responsive {
    margin: auto;
}
#blocVideo::before {
    display: none;
}
#blocTexte {
    padding: 75px 2% 100px 2%;
}
#blocBackground_esthetique {
    min-height: 350px;
}
#blocImages_services {
    margin-top: -50px;
}
#blocTexte-noir .texte {
    top: 45px;
}
#blocTexte-noir {
    padding: 110px 2%;
}
#blocBackground_produit {
    padding: 195px 2%;
}
#texte_section-noir {
    padding: 65px 5% 100px 5%;
}
#blocBackground p.first {
    padding-top: 0;
}
#blocCoordonnees .bloc .texte.courriel {
    margin-left: 0;
}
#blocChartes {
    padding-left: 2%;
    padding-right: 2%;
}
#blocLogo .logo {
    padding: 0 25px;
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap .caption {
    left: 52%;
    top: 50%;
}
#blocBurst, #blocBurst_produit {
    flex-direction: column;
}
#blocBurst a, #blocBurst_produit a {
    margin: auto;
    padding-bottom: 15px;
}
#blocFooter {
    padding: 45% 0 0 0;
    margin-top: -220px;
    flex-direction: column;
}
#blocIntro {
    padding: 50px 5%;
}
#blocLogo .blocLogo {
    flex-wrap: wrap;
}
#blocLogo .logo {
    width: 50%;
    padding-bottom: 25px;
}
#blocLogo .logo .img-responsive {
    margin: auto;
}
#logoQc {
    bottom: 0%;
    padding-left: 2%;
}
#blocFormulaire {
    padding: 100px 2% 0 2%;
}
#blocRealisation .bloc .right-side {
    width: 90%;
    margin: auto;
    padding-top: 30px;
}
#blocRealisation .bloc .left-side {
    margin: auto;
}
#blocRealisation {
    padding: 100px 5% 75px 5%;
}
#blocIntro_glissiere {
    padding: 75px 2% 0 2%;
}
#bloc50-50 {
    padding: 0 2% 55px 2%;
}
#blocVideo iframe {
    width: 665px;
    height: 370px;
}
#blocVideo {
    padding: 100px 2% 50px 2%;
}
#blocImages .image {
    width: 25%;
}
#blocImages_esthetique {
    padding-bottom: 35px;
}
#blocImages_esthetique .image {
    width: 33.3%;
}
#blocImages_services {
    padding: 0;
}
#blocImages_services .image {
    width: 33.3%;
}
#blocBackground_produit p {
    padding-bottom: 65px;
}
#blocBurst_produit {
    padding: 100px 0 50px 0;
}
#blocIntro {
    margin-top: -55px;
}
#blocChartes {
    flex-direction: column;
}
#blocChartes .left-side {
    margin-right: 0;
    padding-bottom: 80px;
}
#blocChartes .left-side .bouton {
    padding: 35px 10% 0 10%;
}
#blocBackground_glissiere p.first {
    margin-top: -100px;
}
}

@media screen and (max-width: $size-xs-max) {
   
.slideshow-wrap .caption {
    left: 36%;
    top: 62%;
}
#blocFooter {
    padding: 90% 0 0 0;
}
#blocFooter .bloc .menu {
    left: 8%;
    width: 80%;
}
#blocFooter {
    margin-top: -215px;
}
#blocFormulaire {
    padding: 50px 2% 0 2%;
}
#blocCoordonnees .bloc .icon {
    width: 14%;
}
#blocCoordonnees .bloc p, #blocCoordonnees .section-texte p {
    font-size: 1.2rem;
}
#blocCoordonnees .left-side .section-texte {
    padding-left: 2%;
}
#blocRealisation .bloc .left-side {
    width: 100%;
}
#blocRealisation .bloc .right-side h3 {
    text-align: left;
}
#blocRealisation .bloc {
    padding-bottom: 30px;
}
#blocBurst {
    padding: 100px 0 50px 0;
}
#blocBackground_glissiere {
    padding: 23% 2% 0 2%;
    min-height: 700px;
}
#blocImages .image {
    width: 50%;
}
#blocVideo iframe {
    width: 380px;
    height: 215px;
}
#bloc50-50 {
    margin-top: -85px;
}
#blocBackground_glissiere p {
    padding-bottom: 6%;
}
#bloc50-50 .right-side h3 {
    padding-bottom: 20px;
}
#blocBackground_esthetique p br, #blocBackground_service p br {
    display: none;
}
#blocBackground_esthetique, #blocBackground_service {
    padding: 0 2%;
}
#blocTexte {
    padding: 75px 5%;
}
#blocImages_esthetique .image, #blocImages_services .image {
    width: 50%;
}
#blocImages_esthetique {
    padding-bottom: 0;
}
#blocImages_services {
    margin-top: -140px;
}
#blocIntro_service {
    padding: 0px 2% 50px 2%;
}
#blocTexte-noir .texte {
    top: 20px;
}
#blocTexte-noir {
    padding: 65px 2%;
    margin-top: -220px;
}
#blocBackground_produit p {
    padding-bottom: 125px;
}
#blocBackground_produit {
    padding: 135px 2% 150px 2%;
}
#blocPDF a.bloc {
    width: 50%;
}
#blocPDF {
    padding: 80px 0 0 0;
}
#blocLogo {
    padding: 50px 5% 0 5%;
}
#blocRealisation {
    padding: 100px 5% 0 5%;
}
#blocChartes .left-side .bouton a {
    font-size: 1rem;
}
#blocBackground_produits {
    margin-top: -45%;
}
}

@media screen and (max-width: 375px) {

#blocFooter .bloc .menu {
    left: 4%;
    width: 75%;
}
#blocCoordonnees .bloc p, #blocCoordonnees .section-texte p {
    font-size: 1.1rem;
}
#blocCoordonnees .bloc {
    padding-bottom: 20px;
}
#blocVideo iframe {
    width: 340px;
    height: 230px;
}
#blocBackground_glissiere p {
    padding-bottom: 13%;
}
#blocChartes .left-side .bouton {
    padding: 35px 2% 0 2%;
}
}