// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts

/* titillium-web-italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Italic'), local('TitilliumWeb-Italic'),
       url('../fonts/titillium-web/titillium-web-v8-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
       url('../fonts/titillium-web/titillium-web-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
       url('../fonts/titillium-web/titillium-web-v8-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold Italic'), local('TitilliumWeb-SemiBoldItalic'),
       url('../fonts/titillium-web/titillium-web-v8-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-600italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
       url('../fonts/titillium-web/titillium-web-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold Italic'), local('TitilliumWeb-BoldItalic'),
       url('../fonts/titillium-web/titillium-web-v8-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-700italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-900 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/titillium-web/titillium-web-v8-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Black'), local('TitilliumWeb-Black'),
       url('../fonts/titillium-web/titillium-web-v8-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titillium-web/titillium-web-v8-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titillium-web/titillium-web-v8-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titillium-web/titillium-web-v8-latin-900.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}

//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Titillium Web", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: "Titillium Web", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1-normal { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1-normal; font-weight: 300; }
.font-1-medium { @extend .font-1-normal; font-weight: 500; }
.font-1-bold { @extend .font-1-normal; font-weight: 700; }
// Libre Baskerville
.font-2-normal { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2-normal; font-weight: 700; }



// MAIN FONTS
.font-main {
    @extend .font-1-bold;
    @extend .text-color;
    font-size: $font-size-20;
}


// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
    text-transform: uppercase;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-2-bold;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-2-bold;
    font-size: $font-size-16;
}
.font-nav-lang {
  @extend .font-1-normal;
  font-size: $font-size-18;
}
// si le logo est du texte
.font-nav-logo {
    @extend .font-2-normal;
    font-size: $font-size-28;
    //@media screen and (max-width: $size-sm-max){
    //    font-size: $font-size-20;
    //}
    //@media screen and (max-width: $size-xs-max){
    //    font-size: $font-size-14;
    //}
}

// FOOTER FONTS

.font-footer {
    @extend .font-1-normal;
    font-size: $font-size-14;
}

