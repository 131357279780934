.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 67%;
        left: 33%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}

